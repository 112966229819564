import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    IconButton ,
    Select, Option ,Textarea 
  } from "@material-tailwind/react";
  import SyntaxHighlighter from 'react-syntax-highlighter';
  import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
  
  import {useState, useEffect} from "react"
  
  export const EntryForm = ({BaseParser,ProcessStart}) => {
    const [showNamesComponents, setShowNamesComponents] = useState([])
    const [target, setTarget] = useState("C# Class")
    const [inputText, setinputText] = useState("parser.add_argument('--batchSize', type=int, default=1, help='input batch size')")
    const [codeText, setcodeText] = useState("")
    const [className, setclassName] = useState("Options")
    useEffect(() => {

    }, [])

    const selectedLanguageSet = (e) => {

      setTarget(e);

    }
    const parserCodeUpdated = (e) => {

      setinputText(e);
      StartConvert();
    }
    const StartConvert = () =>{
      setcodeText(BaseParser.parse(inputText, className, target));
      // ProcessStart({"text":inputText, "target":selectedShow, "className":className});
    }



    return (
       <Card  className="w-auto" color="white" shadow={true} children={
      <div className="p-10">
<Typography variant="h4" color="blue-gray">
          ArgParse Converter
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Convert Python ArgParse to multiple language classes, forms, and more by utilizing a fine-tuned AI.
        </Typography>

        <form className="mt-8 mb-2 w-80 max-w-screen-lg w-full">
          <div className="mb-1 flex flex-col gap-6">

          <Typography variant="h6" color="blue-gray" className="-mb-3">
              Target
            </Typography>
          <Select variant="outlined" id="showSelect" label="Target" defaultValue="C# Class" onChange={selectedLanguageSet}>
          <Option value="C# Class">C# Class</Option>
          <Option value="C# Unity Form">C# Unity Form</Option>
          <Option value="C# UAI Form">C# UAI Form</Option>
          <Option value="Javascript Class">Javascript Class</Option>
          <Option value="React Tailwind Material Form">React Tailwind Material Form</Option>
          <Option value="HTML Form">HTML Form</Option>
          <Option value="HTML Form Page">HTML Form Page</Option>
          <Option value="Python Class">Python Class</Option>
          <Option value="JSON">JSON</Option>
          <Option value="JSON Request">JSON Request</Option>
                </Select>


                <Typography variant="h6" color="blue-gray" className="-mb-3">
              Class Name
            </Typography>
            <Input
              size="lg"
              placeholder="Options"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              onChange={(e) => setclassName(e.target.value)}
              value={className}
            />



              <div className="flex-horizontal w-100">
              <div className="flex-vertical flex-col gap-6 itemContainer">
                <Typography variant="h6" color="blue-gray" className="-mb-3 itemHeader">
              Parser Code
            </Typography>
            <Textarea size="lg" 
              onChange={(e) => parserCodeUpdated(e.target.value)}
              value={inputText}
              className=" h-20 CodeField"
           />
          </div>
        


              <div className="flex-vertical flex-col gap-6 itemContainer">
              <div className="flex-horizontal">
                <Typography variant="h6" color="blue-gray" className="-mb-3 itemHeader">
              Converted Code
            </Typography>

            <IconButton className="copyButton" onClick={() => navigator.clipboard.writeText(codeText)} size="sm" variant="text">
            <i class="fa-solid fa-copy"></i>
    </IconButton>
            <IconButton className="copyButton" onClick={()=>{BaseParser.downloadCodeText(className, target)}} size="sm" variant="text">
            <i class="fa-solid fa-download"></i>
    </IconButton>
</div>


            <SyntaxHighlighter language={ 
              target.includes("Python") ? "python" :
              target.includes("C#") ? "csharp" :
              target.includes("Javascript") ? "javascript" :
              target.includes("React") ? "javascript" :
              target.includes("HTML") ? "html" :
              target.includes("JSON") ? "json" :
              "python"
          } style={docco} className="CodeBlockArea">
      {codeText}
    </SyntaxHighlighter>


            {/* <Textarea size="lg" 
              onChange={(e) => setinputText(e.target.value)}
              value={inputText}
              className=" h-20 CodeField"
            label="Textarea Large" />*/}
          </div> 
        

          </div>
          </div>
          <Button className="mt-6" fullWidth onClick={StartConvert}>
            Convert
          </Button>
        </form>
</div>

       }/>
        
   
    );
  }