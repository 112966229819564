
import {parserToPythonClass} from "./parsers/python";
import {parserToCSharpClass, generateUAIForm, generateUnityForm} from "./parsers/cSharp";
import {generateReactTailwindMaterialComponent} from "./parsers/react";
import {generateHTMLFormPage, generateHTMLForm} from "./parsers/html";
import {sanitizeName, sanitizeText} from "./utils";


export class BaseParserItem {

    constructor(name = "", type = "str", defaultVal = "-", required = false, help = "") {
        this.name = name;
        this.default = defaultVal;
        this.required = required;
        this.help = help;
            }

    asJSONStringItem(){
        return  "\"" +this.name + "\" :" + this.default.toString();

    }
    parseItemTypes(item = ""){
        if (item.includes("default")){
            this.default = item.split("=")[1].trim().replace("'","");
            const sanitizedText = this.default.replace("'","").replace("\"","").replace("\"","");
            // check if sanitizedText is a number
            if (!isNaN(sanitizedText)){
                // check if float or int
                if (sanitizedText.includes(".")){
                    this.default = parseFloat(sanitizedText);
                }
                else{
                    this.default = parseInt(sanitizedText);
                }
            }
            else{
                this.default = sanitizedText;
            const newName = this.default.replace("'","").replace('"inf"', "inf").replace("\"","\\\"");
            this.default = "\""+newName+"\"";

            if (this.default == NaN || this.default == "NaN"){
                this.default = "0";
            }
        }

            return this.default;

        }
        if (item.includes("required")){
            this.required = item.split("=")[1].trim();
            return this.required;

        }
        if (item.includes("help")){
            this.help = item.split("=")[1].trim().replace("'","").replace("\"","");
            const newName = this.help.replace("'","").replace("\"","");
            this.help = "\""+newName+"\"";
            return this.help;

        }
        if (item.includes("type")){
            this.type = item.split("=")[1].trim();
            if (item.includes("int")){
                
                try{
            if (this.default.includes("inf")){
                this.default = "99999";
            }
        }catch{
            
        }
            }
            return this.type;

        }
        if (item.includes("action")){
            this.type = "bool";
            if (item.includes("store_true")){
                this.default = "false";
            }
            if (item.includes("store_false")){
                this.default = "true";
            }
            return this.type;

        }
    }

    parseParserLine(line = []){
        
        // console.log(line);
        for (let i = 0; i < line.length; i++){
            let item = line[i].trim();
            if (item.includes("--")){
                this.name = sanitizeName(item.replace("--", "").trim().replace("'","").replace("\"",""));
                // remove quotes both single and double from this.name
                const newName = this.name.replace("'","").replace("\"","");
                this.name = sanitizeName(newName);
            }
            if (item.includes("default")){
                this.default = item.split("=")[1].trim().replace("'","");
                
                const newName = this.default.replace("'","").replace('"inf"', "inf").replace("\"","\\\"");
                this.default = "\""+newName+"\"";
            }
            if (item.includes("required")){
                this.required = item.split("=")[1].trim();
            }
            if (item.includes("help")){
                this.help = item.split("=")[1].trim().replace("'","").replace("\"","");
                const newName = this.help.replace("'","").replace("\"","");
                this.help = "\""+newName+"\"";
            }
            if (item.includes("type")){
                this.type = item.split("=")[1].trim();
                if (item.includes("int")){
                    
                if (this.default.includes("inf")){
                    this.default = "99999";
                }
                }
            }
            if (item.includes("action")){
                this.type = "bool";
                if (item.includes("store_true")){
                    this.default = "false";
                }
                if (item.includes("store_false")){
                    this.default = "true";
                }
            }
        }
        if(this.default == '-' && this.type == "str"){
            this.default = "-";
        }
    }
    
getNextCommaItem(text = "" ){
    const regexNextItem = new RegExp('^[^,]+');
    let match = regexNextItem.exec(text);
    const nameText = match[0].trim().replace("--", "").replace("'","", 5).replace("'","", 5).replace("\"","");
    let updatedText = text.replace(match[0], "");
    // remove first comma from updatedText
    updatedText = updatedText.replace(",","").trim();
    return {"value":nameText, "text":updatedText};
}


parseParserText(text = "" ){
        
    
    const nameDict = this.getNextCommaItem(text);
    this.name = sanitizeName(nameDict.value);
    text = nameDict.text;

    // Create a regex to match any text or number in a string
    while (text.replace(/\s/g, '').length > 0)  {

        let nextDict = this.getNextCommaItem(text);
        const itemtype = this.parseItemTypes(nextDict.value);
        text = nextDict.text;

    }
    if(this.default == '-' && this.type == "str"){
        this.default = "\"-\"";
    }
return this;

}

}




export class BaseParser {

    constructor( className="Options") {
this.className = className;
this.options = [];
this.parsedText = [];
this.parsedTextLinesRaw = [];
this.pythonClass = "";
this.cSharpClass = "";
this.javascriptClass = "";
this.htmlForm = "";
this.htmlFormPage = "";
this.reactForm = "";
this.unityForm = "";
this.unityUAIForm = "";
this.rawText = "";
    }

    asJSON(){
        let clone = new BaseParser();
        clone.options = this.options;
        clone.options.forEach((item)=>{
            if(item.type == "str"){
                item.default = sanitizeText(item.default);
            }
        });
        return JSON.stringify({"classes": clone});
    }

    
    asJSONRequest(){
        let clone = new BaseParser();
        let outText = "{";
        this.options.forEach((item)=>{
            outText += item.asJSONStringItem() + ",";
        });
        outText = outText.slice(0, -1);
        outText += "}";
        return outText;
      
    }


    parseParserLine(line = []){

        let parseItem = new BaseParserItem();
        
        this.options.push(parseItem.parseParserLine(line));
    }

    parseParserLineText(line = ""){

        let parseItem = new BaseParserItem();
        this.options.push(parseItem.parseParserText(line));

    }

    parseParserLines(){
        const splitText = this.rawText.split("\n");
        const regex2 = new RegExp('(?<=add_argument\\()(.*)(?=\\))', 'm');
        this.parsedTextLinesRaw = [];
        splitText.forEach(line => {
            let match = regex2.exec(line);
            if (match){
                this.parseParserLineText(match[0]);
                // this.parseParserLine(match[0].split(","));
                this.parsedTextLinesRaw.push(match[0].split(","));
            }
        });
    }

    parseText(parserText){
        this.rawText = parserText;
        this.parseParserLines();
        return this.options;
    }

    parse(parserText, className = "Options", target = "Python"){
        this.options = [];
        this.parseText(parserText);
        console.log(this.options);
        switch(target){
            case "Python" || "Python Class":
                this.pythonClass = parserToPythonClass(this, className);
                return this.pythonClass;
            
            case "C# Class" || "CSharp Class":
                this.cSharpClass = parserToCSharpClass(this, className, true);
                return this.cSharpClass;
            
            case "HTML Form Page":
                this.htmlFormPage = generateHTMLFormPage(className,this.options);
                return this.htmlFormPage;

            
            case "HTML Form":
                this.htmlForm = generateHTMLForm(className,this.options);
                return this.htmlForm;
            
            case "React Tailwind Material Form" :
                this.reactForm = generateReactTailwindMaterialComponent(className,this.options);
                return this.reactForm;
            case "C# UAI Form" :
                this.unityUAIForm = generateUAIForm(this,className);
                return this.unityUAIForm;
            case "C# Unity Form" :
                this.unityForm = generateUnityForm(this,className);
                return this.unityForm;
            
            case "JSON" :
                return this.asJSON();
            
            case "JSON Request" :
                return this.asJSONRequest();
            
            default:
                this.pythonClass = parserToPythonClass(this, className);
                return this.pythonClass;
        }
    }

    downloadCodeText(className = "Options", target = "Python"){
        var element = document.createElement('a');
        var extension = "";
        var codeText = "";
        if(target.includes("Python")){
        extension = "py";
        codeText = this.pythonClass;
        }
        if(target.includes("C#")){
        extension = "cs";
        codeText = this.cSharpClass;
        
        if(target.includes("Unity")){
            codeText = this.unityForm;
            }
        if(target.includes("UAI")){
            codeText = this.unityUAIForm;
            }
        }
        if(target.includes("Javascript")){
        extension = "js";
        codeText = this.javascriptClass;
        }
        if(target.includes("React")){
        extension = "js";
        codeText = this.reactForm;
        }
        if(target=="HTML Form Page"){
        extension = "html";
        codeText = this.htmlFormPage;
        }
        if(target=="HTML Form"){
        extension = "html";
        codeText = this.htmlForm;
        }
        if(target=="JSON"){
        extension = "json";
        codeText = this.asJSON();
        }
        if(target=="JSON Request"){
        extension = "json";
        codeText = this.asJSONRequest();
        }

    
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(codeText));
        element.setAttribute('download', className + "." + extension);
    
        element.style.display = 'none';
        document.body.appendChild(element);
    
        element.click();
    
        document.body.removeChild(element);
    }


}