import {valueToType, replaceAll} from "../utils.js";

const ImportForm = `
<!DOCTYPE html>
<html>
<body>
`;


const GenerateInput = (prop) => {
    let val = valueToType(prop.default, prop.type);
    let typeBool = false;
    if (val == "True" || val == true) {
        val = "true";
        typeBool = true;
    } else if (val == "False" || val == false) {
        val = "false";
        typeBool = true;
    }
    let inputType = "text";
    if (typeof prop.default == "number") {
        inputType = "number";
    }
    if (typeBool) {
        return `
            ${prop.name.replace('_', ' ').toUpperCase()}
            <input id="${prop.name.replace(' ', '')}" name="${prop.name.replace(' ', '')}" type="checkbox"><br>
                    `;
    }
    return `
            ${prop.name.replace('_', ' ').toUpperCase()}
            <input placeholder=${val} type="${inputType}"><br>
                    `;
   
}

export const generateHTMLForm = (className, props = []) => {
    let outArray = [];
  
    outArray.push(`<h2>${className}</h2>`);
    outArray.push("");
    outArray.push("<form id=\"frm1\" >");
    
    for (let prop of props) {
        outArray.push("");
        outArray.push(GenerateInput(prop));
        outArray.push("");
        outArray.push("");
    }
    outArray.push(`
                      <input type="button" onclick="myFunction()" value="Submit">
                      </form>

<script>
function myFunction() {
  
}
</script>
`);
return outArray.join("\n");
}

export const generateHTMLFormPage = (className, props = []) => {
    let outArray = [];
    outArray.push("");
    outArray.push(ImportForm);
    outArray.push("");
    outArray.push(generateHTMLForm(className, props));
    outArray.push(`
</body>
</html>
                    `);
    
    return outArray.join("\n");
}