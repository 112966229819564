import {valueToType, replaceAll} from "../utils.js";

const ImportForm = `
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Select, Option 
    } from "@material-tailwind/react";
`;

const UseEffect = `
useEffect(() => {
}, [])
`;

const SubmitedClicked = `
const submitClicked = (e) => {
}
`;



const TopClassHeader = (className, props = [] )=>{

    return "export const "+className+" = ({"+" }) => {"
}


const GenerateStates = (props) => {
    let stateItems = [];
    for (let prop of props) {
        let val = valueToType(prop.default, prop.type);
        if (val == "True" || val == true) {
            val = "true";
        } else if (val == "False" || val == false) {
            val = "false";
        }
        stateItems.push("const [" + prop.name.replace(' ', '') + ", set" + prop.name.replace(' ', '') + "] = useState(" + val + ")");
    }
    return stateItems.join("\n");
}



const GenerateInput = (prop) => {
    let val = valueToType(prop.default, prop.type);
    let typeBool = false;
    if (val == "True" || val == true) {
        val = "true";
        typeBool = true;
    } else if (val == "False" || val == false) {
        val = "false";
        typeBool = true;
    }
    let inputType = "text";
    if (typeof prop.default == "number") {
        inputType = "number";
    }
    if (typeBool) {
        return "<Checkbox label=\"" + prop.name.replace('_', ' ').toUpperCase() + "\" value={" + prop.name.replace(' ', '') + "} onChange={(e) => set" + prop.name.replace(' ', '') + "(e.target.value)} />".replace("%LABELSET%", prop.name.replace(' ', '')).replace("%DEFAULT%", val.toString());
    }
    return "<Typography variant=\"h6\" color=\"blue-gray\" className=\"-mb-3\">\n" + prop.name.replace('_', ' ').toUpperCase() + "\n</Typography>\n<Input\nsize=\"lg\"\nplaceholder={" + val + "}\ndefaultValue={" + val + "}\nvalue={" + val + "}\ntype=\"" + inputType + "\"\nclassName=\" !border-t-blue-gray-200 focus:!border-t-gray-900\"\nlabelProps={{\nclassName: \"before:content-none after:content-none\",\n}}\nonChange={(e) => set" + prop.name.replace(' ', '') + "(e.target.value)}\n/>\n";
}

export const generateReactTailwindMaterialComponent = (className, props = []) => {
    let outArray = [];
    outArray.push("");
    outArray.push(ImportForm);
    outArray.push("");

    outArray.push("");
    outArray.push(TopClassHeader(className, props));
    outArray.push("");
    outArray.push("");
    outArray.push(GenerateStates(props));
    outArray.push("");
    outArray.push("");
    outArray.push(UseEffect);
    outArray.push("");
    outArray.push(SubmitedClicked);
    outArray.push("");
    outArray.push("");
    outArray.push("    return (");
    outArray.push(`
                     <Card  className="w-auto" color="white" shadow={true} children={
      <div className="p-10">
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
          <div className="mb-1 flex flex-col gap-6">

          <Typography variant="h6" color="blue-gray" className="-mb-3">
                    `
                    );
    outArray.push(className);
    outArray.push("</Typography>");
    
    for (let prop of props) {
        outArray.push("");
        outArray.push(GenerateInput(prop));
        outArray.push("");
        outArray.push("");
    }
    outArray.push(`
                    </div>
        <Button className="mt-6" fullWidth onClick={submitClicked}>
            Submit
        </Button>
        </form>
        </div>

    }/>
        

    );
}
    
`);
return outArray.join("\n");
}
