
import { Typography } from "@material-tailwind/react";


export const Footer = () => {

    return (
<footer className="centerItem flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between">
      <Typography color="blue-gray" className="font-normal text-center">
        <p className="text-center">
        &copy; 2023 <a href="https://uai.software">UAI Software Inc.</a>
        </p>
      </Typography>
      {/* <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
        <li>
          <Typography
            as="a"
            href="https://www.uai.software/"
            color="blue-gray"
            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            Home
          </Typography>
        </li>
        
      </ul> */}
    </footer>
    );
}