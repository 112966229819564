import {valueToType} from "../utils.js";

export const parserToPythonClass = (baseParser, className = "Options") => {
    let classes = ["class " + className + ":", "", "", "    def __init__(self):"];
    let optionsJson = baseParser;
    for (let item of optionsJson.options) {
        let devaultVal = item.default;
        devaultVal = valueToType(devaultVal, item.type);

        if (item.default == "true" || item.default == true) {
            devaultVal = "True";
        }
        if (item.default == "false" || item.default == false) {
            devaultVal = "False";
        }
        let outText = "        self." + item.name.replace(' ', '_') + " = " + devaultVal;
        classes.push(outText);
    }
    classes.push("");
    classes.push("");
    classes.push("# -------------- Help --------------");
    classes.push("");
    for (let item of optionsJson.options) {
        if (item.help != "" && item.help != null) {
            let outText = "        self." + item.name.replace(' ', '_') + "_Help = " + item.help;
            classes.push(outText);
        }
    }
    classes.push("");
    let outData = classes.join("\n");
    return outData;
}