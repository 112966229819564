import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import {CrossPythonArgParser} from "./pages/crossParser";

export default function WrappedRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CrossPythonArgParser />}>
          <Route index element={<App />} />
          <Route path="/Python/ArgParse" element={<CrossPythonArgParser />} />
          {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<WrappedRoutes />);