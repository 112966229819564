import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
  } from "@material-tailwind/react";
  import { Spinner } from "@material-tailwind/react";

  import {EntryForm} from "./components/EntryForm"
  import {BaseParser} from "./api/crossParser/baseParser.js"
  import {useState, useEffect} from "react"
  import {Footer} from "./components/Footer"

  export const  CrossPythonArgParser = () => {

    const [hitsAPI, setHitsAPI] = useState(null)
    const [horseShow, setHorseShow] = useState(null)
    const [baseParser, setBaseParser] = useState(null)
    const [processDict, setProcessDict] = useState({"text":"","target":"", "className":"Options"})
    const [mode, setMode] = useState("search")

    useEffect(() => {

        setBaseParser(new BaseParser("Thing"));


    }, [])

    const ProcessClicked = (showDict)=>{
        setProcessDict(showDict);
        baseParser.parsePythonParser(showDict.text);
        console.log(baseParser.options);
    }


    return (
        <div className="flex flex-col items-center justify-center min-h-screen py-2">
            {/* {mode == "search" ? <HorseSearchForm SearchHorseShow={ProcessClicked}/> :
            mode == "loading" ? <Spinner className="h-12 w-12" /> :
            mode == "result.horseData" && matchedHorse != null ? <HorseData horseInfo={matchedHorse} /> :
            <HorseSearchForm SearchHorseShow={ProcessClicked}/>} */}
            <EntryForm ProcessStart={ProcessClicked} BaseParser={baseParser}/>
            <Footer/>
      
      </div>
    );
  }