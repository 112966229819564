import {valueToType} from "../utils.js";
export const parserToCSharpClass = (baseParser, className = "Options", addUsing = true) => {
    let classes = [];
    if (addUsing == true){
        classes = ["using System;"]
    }
    let optionsJson = baseParser;


    let classesadd = ["","","[System.Serializable]","public class "+className+" {", "", ""];
    for (let item of classesadd) {
        classes.push(item);
    }
    optionsJson = baseParser;
    for (let item of optionsJson.options) {
        let devaultVal = valueToType(item.default, item.type);
        let type = item.type;
        if (item.type == "float") {
            devaultVal = devaultVal.toString() + "f";
        }
        if (type == "str") {
            type = "string";
        }
        let outText = "public " + type + " " + item.name.replace(' ', '_') + " = " + devaultVal + ";";
        classes.push(outText);
    }
    classes.push("");
    classes.push("");
    classes.push("// -------------- Help --------------");
    classes.push("");
    
    for (let item of optionsJson.options) {
        if (item.help != "" && item.help != null) {
            let outText = "public string " + item.name.replace(' ', '_') + "_Help = " + item.help + ";";
            classes.push(outText);
        }
    }
    classes.push("");
    classes.push("");
    classes.push("}");
    return classes.join("\n");
}



// def generateUnityForm(parser, className = "ImageForm"):
//     from parsers.uai import getTMP_InputField, getToggle, getDropDown
//     classes = ["using System;",
// "using System.Collections.Generic;",
// "using System.Text;",
// "using TMPro;",
// "using UnityEngine;",
// "using UnityEngine.UI;", "","","public class "+className+" : MonoBehaviour{", "", ""]
//     optionsJson = parserToJSON(parser, className)
//     for item in optionsJson["options"]:
//         outText = getTMP_InputField(item['name'].replace(' ', '_'))
//         if item['type'] == "float":
//             item['default'] = str(item['default'])+"f"
//             outText = getTMP_InputField(item['name'].replace(' ', '_'))
//         if item['type'] == "int":
//             outText = getTMP_InputField(item['name'].replace(' ', '_'))
//         if item['type'] == "string" or  item['type'] == "str"   :
//             outText = getTMP_InputField(item['name'].replace(' ', '_'))
//         if item['type'] == "bool" :
//             outText = getToggle(item['name'].replace(' ', '_'))
//         classes.append(outText)
//     classes.append("")  
//     classes.append("")  
//     classes.append("}")  
//     outData = "\n".join(classes)
//     return outData





const getTMP_InputField = (name) => {
    return `   public TMP_InputField ${name};`
}   

const getToggle = (name) => {
    return `   public Toggle ${name};`
}

const getDropDown = (name) => {
    return `   public TMP_Dropdown ${name};`
}

const getDoubleSpinbox = (name) => {
    return `   public ui_DoubleSpinBox ${name};`
}

const getSpinbox = (name) => {
    return `   public ui_SpinBox ${name};`
}

const getLineEdit = (name) => {
    return `   public ui_LineEdit ${name};`
}



export const generateUnityForm = (parser, className = "ImageForm") => {
    let classes = ["using System;",
    "using System.Collections.Generic;",
"using System.Text;",
"using TMPro;",
"using UnityEngine;",
"using UnityEngine.UI;", "","",
"public class "+className+" : MonoBehaviour{",
 "", ""]
 let optionsJson = parser;
    for (let item of optionsJson.options) {
        let outText = getTMP_InputField(item.name.replace(' ', '_'))
        if (item.type == "float") {
            item.default = item.default.toString()+"f"
            outText = getTMP_InputField(item.name.replace(' ', '_'))
        }
        if (item.type == "int") {
            outText = getTMP_InputField(item.name.replace(' ', '_'))
        }
        if (item.type == "string" ||  item.type == "str"   ) {
            outText = getTMP_InputField(item.name.replace(' ', '_'))
        }
        if (item.type == "bool" ) {
            outText = getToggle(item.name.replace(' ', '_'))
        }
        classes.push(outText);
    }
    classes.push("");
    classes.push("") ; 
    classes.push("")  ;
    classes.push("}")  ;
    return classes.join("\n");


}



//     for item in optionsJson["options"]:
//         outText = ""
        
// nameText = item['name'].replace(' ', '_')
// if nameText == "name":
//     nameText = "name_"
// if item['type'] == "float":
//     item['default'] = str(item['default'])+"f"
//     outText = getDoubleSpinbox(nameText)
// if item['type'] == "int":
//     outText = getSpinbox(nameText)
// if item['type'] == "string" or  item['type'] == "str"   :
//     outText = getLineEdit(nameText)
// if item['type'] == "bool" :
//     outText = getToggle(nameText)
// classes.append(outText)
// classes.append("""
//     void Start()
// {
//     runnerType = RunnerType.Python;
//     onOpenFile.AddListener(OnImportImage);
// }

// private void OnImportImage(bool arg0, string filepath)
// {

//     GameState.ShowMediaFilepath(filepath);
// }

// """)
// classes.append("""
// public override void SetRequest()
// {
//     base.SetRequest();
// """)
// classes.append(f"runner.SetModulePath(\"{className}\");")
        


export const generateUAIForm = (parser, className = "ImageForm") => {
    const classes = ["using System;",
"using System.Collections.Generic;",
"using System.Linq;",
"using System.Text;",
"using System.Threading.Tasks;",
"using TMPro;",
"using uai.ai;",
"using uai.common;",
"using uai.common.objects;",
"using uai.networking;",
"using uai.ui;",
"using UnityEngine;",
"using UnityEngine.UI;","","","namespace uai.runners.ai{", "" , "public class "+className+`Form : BaseRunner`+className.replace(' ', ('_'))+`Request, MultipleMediaRequest>{{`, "", "" ];

let optionsJson = parser;
for (let item of optionsJson.options) {
    let outText = "";
    let nameText = item.name.replace(' ', '_');
    if (nameText == "name") {
        nameText = "name_";
    }
    if (item.type == "float") {
        item.default = item.default.toString()+"f";
        outText = getDoubleSpinbox(nameText);
    }
    if (item.type == "int") {
        outText = getSpinbox(nameText);
    }
    if (item.type == "string" ||  item.type == "str"   ) {
        outText = getLineEdit(nameText);
    }
    if (item.type == "bool" ) {
        outText = getToggle(nameText);
    }
    classes.push(outText);
}
    classes.push(`    void Start()
    {
        runnerType = RunnerType.Python;
        onOpenFile.AddListener(OnImportImage);
    }
    
    private void OnImportImage(bool arg0, string filepath)
    {
    
        GameState.ShowMediaFilepath(filepath);
    }`);
    classes.push(`    public override void SetRequest()
    {
        base.SetRequest();`);
    classes.push("runner.SetModulePath(\""+className+"\");");


    for (let item of optionsJson.options) {
        classes.push("")
        classes.push("try")
        classes.push("{")
        let nameText = item.name.replace(' ', '_');
        if (nameText == "name") {
            nameText = "name_";
        }
        if (item.type == "bool" || item.type == Boolean   ) {
            classes.push("request."+item.name.replace(' ', '_')+" = "+nameText.replace(' ', '_')+".isOn;");
        } else {
            classes.push("request."+item.name.replace(' ', '_')+" = "+nameText.replace(' ', '_')+".value;");
        }
        classes.push("}");
        classes.push("catch");
        classes.push("{");
        classes.push("}");
        classes.push("");
    }
    classes.push(`
        }
        public override void Finished()
        {
            result = JsonUtility.FromJson<MultipleMediaRequest>(recievedStringData);
            mediaCatalog.AddPack(result);
            GameState.SetResultType("MultipleMediaRequest.Base64");
            base.Finished();
        }
                `)  ;
    classes.push("")  ;
    classes.push("")  ;
    classes.push("}") ;
    classes.push("") ; 
    classes.push(parserToCSharpClass(parser, className = className.replace(' ', ('_')) +"Request", false)) ;
    classes.push("")  ;
    classes.push("}")  ;
    classes.join("\n");
    return classes.join("\n");

}