
export  const sanitizeName = (name = "") => {
    let newName = name.replace("'","").replace("\"","").replace
    return newName.replace("'","").replace("\"","");
}   

export const sanitizeText = (text = "") => {
    let outText =text;

    if(isNaN(text)){
        outText = parseFloat(text);
    }
    try{
    outText = outText.replace('"', '');
    outText = outText.replace("\"", "");
    }catch(e){

        try{
            outText = parseInt(text);
            outText = outText.replace('"', '');
            outText = outText.replace("\"", "");
            }catch(e){
        
            }
    }
    return outText;
}
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  export function replaceAll(str, find, replace) {
    while(str.includes(find)){
        str = str.replace(find, replace);
     }
    return str;

  }

export const valueToType = (value = 0, type="float") => {

    const text = sanitizeText(value);
    if (type == "float"){
        try{
        if(text.includes("inf")){
            return 9999;
        }
    }catch{

        if(isNaN(text)){
            return 999;
        }

    }
        let parsed = parseFloat(text);
        if(parsed == NaN){
            parsed = 0;
        }
        return parsed;
    }
    if (type == "int"){

        try{
        if(text.includes("inf")){

            return 9999;
        }
    }catch{

        if(isNaN(text)){
            return 999;
        }

    }
        let parsed = parseInt(text);
        if(isNaN(text)){
            parsed = 0;
        }

        if (isNaN(text) || parsed == "NaN"){
            parsed = 15;
        }
        return parsed;
    }
    if (type == "bool"){

        if (text == "true" || text == true || text == 1 || text == "1" || text == "True" || text == "TRUE" || text == "t" || text == "T" || text == "y" || text == "Y" || text == "yes" || text == "Yes" || text == "YES" || text == "on" || text == "On" || text == "ON" || text == "enabled" || text == "Enabled" || text == "ENABLED" || text == "enable" || text == "Enable" || text == "ENABLE"){
            return true;
        }
        if (text == "false" || text == false || text == 0 || text == "0" || text == "False" || text == "FALSE" || text == "f" || text == "F" || text == "n" || text == "N" || text == "no" || text == "No" || text == "NO" || text == "off" || text == "Off" || text == "OFF" || text == "disabled" || text == "Disabled" || text == "DISABLED" || text == "disable" || text == "Disable" || text == "DISABLE"){
            return false;
        }
    }
    if(value == NaN){
        value = 0;
    }
    return value;
}